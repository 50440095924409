<template>
    <breadcrumb btnText="返回" btnIcon="n-direction-left" @btn-click="$router.go(-1)" />

    <div class="page-equipment-create" v-if="!result.show">
        <equipment-form :category="category" :btnLoading="loading" @submit="submit" />
    </div>

    <operation-result
        v-model:show="result.show"
        :duration="result.duration"
        :nextText="result.nextText"
        :title="result.title"
        :status="result.status"
        @go-next="reload"
    />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import EquipmentForm from '@/components/forms/EquipmentForm.vue';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import OperationResult from '@/components/layout/OperationResult.vue';

@Options({
    components: {
        EquipmentForm, Breadcrumb, OperationResult
    },
    data(){
        return {
            category: {},
            result: {
                show: false,
                title: '',
                duration: 0,
                toTitle: '',
                backText: '',
                nextText: '',
                status: 'SUCCESS'
            },
            loading: false
        }
    },
    mounted(){
        if(this.$route.params.id)
            this.getCategory(Number(this.$route.params.id));
        else this.$router.go(-1);
    },
    inject: ['reload'],
    methods: {
        getCategory(id: number){
            this.$api.xrCategoryShow(id).then((res: any) => {
                this.category = {
                    id: res.data.id,
                    title: res.data.title,
                    type: res.data.type
                };
            })
        },
        submit(e: any) {
            this.loading = true;
            this.$api.xrStoreEquipment(e).then((res: any) => {
                this.loading = false;
                if(res.msg === 'success'){
                    this.result = {
                        show: true,
                        title: '设备创建成功',
                        duration: 0,
                        nextText: '继续创建',
                        status: 'SUCCESS'
                    }
                } else {
                    this.result = {
                        show: true,
                        title: '设备创建失败，接口报错，请联系技术人员检查',
                        duration: 0,
                        nextText: '继续创建',
                        status: 'ERROR'
                    }
                }
            })
        }
    }
})

export default class EquipmentCreate extends Vue {}
</script>